import dayjs from 'dayjs'
import TYPE from '@/data/type'
import DISH from '@/data/dish'
import ROUTER from '@/data/router'
import ENDPOINT from '@/config/endpoint'
import CONSTANTS from '@/config/constants'
import { loginInfo } from '@/services/auth'

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1)
}

export function convertDateTime(timestamp, format = 'ss:mm:HH DD/MM/YYYY') {
  return dayjs(timestamp * 1000).format(format)
}

export function numberWithDelimiter(number, delimiter = '.') {
  return Math.round(number)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, delimiter)
}

export function getTranslation(object, lang) {
  if (object.translation) {
    return object.translation
  }
  if (!object.translations) {
    return object
  }
  return object.translations.find((t) => t.locale === lang)
}

export function convertMenus(type) {
  const menu = ROUTER.MENUS.find((m) => m.type === type)
  if (menu) {
    return menu.title
  }
  return ''
}

export function getDataJs(data, page = 1, limit = 10, keyword = '', searchBy = 'name') {
  const offset = (page - 1) * limit
  let result = data
  if (keyword) {
    result = data.filter((d) => keyword.split(' ').every((v) => d[searchBy].toLowerCase().includes(v.toLowerCase())))
  }
  return {
    data: result.slice(offset).slice(0, limit),
    total: result.length
  }
}

export function getPlaceType(type) {
  const placeType = TYPE.PLACES.find((m) => m.value === type)
  if (placeType) {
    return placeType.name
  }
  return type
}

export function getCountry(value, countries, key = 'id') {
  if (key === 'name') {
    return countries.find((m) => m[key] && !!value && m[key].toLowerCase() === value.toLowerCase())
  }
  return countries.find((m) => m[key] === value)
}

export function getArea(value, areas, key = 'id') {
  if (key === 'name') {
    return areas.find((m) => m[key] && !!value && m[key].toLowerCase() === value.toLowerCase())
  }
  return areas.find((m) => m[key] === value)
}

export function getCategory(value, categories, key = 'id') {
  if (key === 'name') {
    return categories.find((m) => m[key] && !!value && m[key].toLowerCase() === value.toLowerCase())
  }
  return categories.find((m) => m[key] === value)
}

export function getTag(value, tags, key = 'id') {
  if (key === 'name') {
    return tags.find((m) => m[key] && !!value && m[key].toLowerCase() === value.toLowerCase())
  }
  return tags.find((m) => m[key] === value)
}

export function getAccountOrg() {
  const authUser = loginInfo() ? loginInfo().auth_user : null
  if (authUser && authUser.staff_type === 'moderator') {
    return authUser.organizations && authUser.organizations[0] ? authUser.organizations[0] : null
  }
}

export function getAccountMenus() {
  const authUser = loginInfo() ? loginInfo().auth_user : null
  const accountType = authUser ? authUser.staff_type : ''
  if (accountType && accountType === 'moderator') {
    const org = getAccountOrg()
    const menus = org ? org.menus.split(',') : []
    return menus
  }
  return CONSTANTS.APP_MENUS ? CONSTANTS.APP_MENUS.split(',') : '*'
}

export function getAccountRouters() {
  const menus = getAccountMenus()
  if (menus === '*') {
    return ROUTER.MENUS
  }
  return menus.map((m) => {
    return ROUTER.MENUS.find((r) => r.type === m)
  })
}

export function getPlaceTypes(isHide = true, blockKeys = []) {
  const menus = getAccountMenus()
  const types = TYPE.PLACES.filter((p) => !blockKeys.includes(p.value) && (isHide ? !p.hide : true))
  if (menus === '*') {
    return types
  }
  return types.filter((t) => menus.includes(t.value))
}

export function getBannerTypes(position) {
  const types = TYPE.PLACES.filter((m) => position.options.includes(m.value)).map((m) => m.name)
  return types.join(', ')
}

export function getOrgName(organization) {
  return organization ? organization.summary : 'Chưa có'
}

export function parseEndpointCategory(type) {
  return ENDPOINT.PLACE_CATEGORIES
}

export function orgContents(type, organization) {
  if (organization) {
    return organization[type] || []
  }
  return []
}

export function orgContentIds(type, organization) {
  return orgContents(type, organization).map((c) => c.id)
}

export function getDishType(type) {
  const dishType = DISH.TYPES.find((m) => m.value === type)
  if (dishType) {
    return dishType.label
  }
  return type
}

export function getDishSize(size) {
  const dishSize = DISH.SIZES.find((m) => m.value === size)
  if (dishSize) {
    return dishSize.label
  }
  return size
}

export function parseExecutedStatus(status) {
  const cartStatus = DISH.MANAGE_CART_STATUS
  return cartStatus.find((s) => s.value === (status || 'new'))
}

export function parsePaymentStatus(status) {
  const cartStatus = DISH.MANAGE_PAYMENT_STATUS
  return cartStatus.find((s) => s.value === (status || 'open'))
}

export function parseDishType(type) {
  const types = DISH.TYPES
  const typeValue = types.find((s) => s.value === type)
  return typeValue ? typeValue.label : 'Đồ ăn'
}

export function parseDishSubscriptionStatus(dishSubscription) {
  const status = DISH.SUBSCRIPTION_STATUS
  const statusValue = status.find((s) => s.value === dishSubscription)
  return statusValue
}

export function getToShowRouter(row, routerType = '') {
  const newType = routerType || this.type || ''
  const firstText = newType.slice(0, 1).toUpperCase()
  const type = `${firstText}${newType.slice(1, newType.length)}`
  return { name: `${type}Info`, params: row ? { id: row.id } : {}}
}

export function getUserRoles() {
  const keys = CONSTANTS.USER_ROLE_KEYS
  let roles = TYPE.ROLES
  const staffType = loginInfo().auth_user.staff_type
  if (keys) {
    roles = TYPE.ROLES.filter((r) => keys.split(',').filter(Boolean).includes(r.value))
  }
  return roles.filter((r) => r.rules.includes(staffType))
}

export function getItemChildren(parent, items, allItems) {
  const children = items.filter((i) => {
    const isLevel = i.pathLevels.length === parent.pathLevels.length + 1
    const isParent = i.pathLevels.slice(0, parent.pathLevels.length).join('.') === parent.path
    const isLink = i.link_path
    return isLevel && isParent && !isLink
  })
  const sameLevels = allItems.filter((i) => i.pathLevels.length === parent.pathLevels.length && parent.pathLevels.slice(0, parent.pathLevels.length - 1).join('.') === i.pathLevels.slice(0, parent.pathLevels.length - 1).join('.'))
  const secondPerson = allItems.find((item) => !children.find((i) => i.id === item.id) && item.link_path && item.link_path === parent.path)
  const otherItems = items.filter((item) => !children.find((i) => i.id === item.id) && !(secondPerson && secondPerson.id === item.id))
  return {
    firstPerson: {
      ...parent,
      sameLevels: sameLevels,
      secondPerson: secondPerson || null
    },
    secondPerson: secondPerson || null,
    children: children.map((ch) => getItemChildren(ch, otherItems, allItems)).reverse()
  }
}
